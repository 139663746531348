import React, { useCallback, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { stylesDefaultSecondaryButton } from '../../util/styles';

export function initButtonToggle(isInProgress, setIsInProgress) {
  let localIsInProgress = false;

  return {
    disableButton() {
      // local variable fixes situation where state may not be set immediately
      // state variable ensures variable is saved across renders
      if (localIsInProgress || isInProgress) {
        return false; // we failed to disable the button
      }
      localIsInProgress = true;
      setIsInProgress(true);
      return true; // we successfully disable the button
    },
    enableButton() {
      // local variable fixes situation where state may not be set immediately
      // state variable ensures variable is saved across renders
      if (localIsInProgress || isInProgress) {
        localIsInProgress = false;
        setIsInProgress(false);
        return false; // we successfully enable the button
      }
      return true; // we failed to enable the button
    },
  };
}

interface SubmitButtonProps {
  handleSubmit: () => Promise<any>;
  dataCy?: string;
  classes;
  disabledCondition?: boolean;
  children: ReactNode;
}
export function SubmitButton(props: SubmitButtonProps) {
  const { handleSubmit, dataCy, classes, disabledCondition, children } = props;
  const [isInProgress, setInProgress] = useState(false);
  const buttonToggle = initButtonToggle(isInProgress, setInProgress);
  const { disableButton, enableButton } = useCallback(buttonToggle, []);

  let buttonProps = {};

  if (classes) {
    buttonProps = { ...buttonProps, classes };
  }

  if (dataCy) {
    buttonProps = { ...buttonProps, 'data-cy': dataCy };
  }

  return (
    <Button
      disabled={disabledCondition || isInProgress}
      onClick={async () => {
        if (disableButton()) {
          const error = await handleSubmit();
          if (error) {
            enableButton();
          }
        }
      }}
      {...buttonProps}
    >
      {children}
    </Button>
  );
}

export function SubmitIconButton({ handleSubmit, dataCy, classes, disabledCondition, children }) {
  const [isInProgress, setInProgress] = useState(false);
  const buttonToggle = initButtonToggle(isInProgress, setInProgress);
  const { disableButton, enableButton } = useCallback(buttonToggle, []);

  let buttonProps = {};

  if (classes) {
    buttonProps = { ...buttonProps, classes };
  }

  if (dataCy) {
    buttonProps = { ...buttonProps, 'data-cy': dataCy };
  }

  return (
    <IconButton
      disabled={disabledCondition || isInProgress}
      onClick={async () => {
        if (disableButton()) {
          const error = await handleSubmit();
          if (error) {
            enableButton();
          }
        }
      }}
      {...buttonProps}
    >
      {children}
    </IconButton>
  );
}

export function SubmitMenuItem({ handleSubmit, dataCy, classes, disabledCondition, children }) {
  const [isInProgress, setInProgress] = useState(false);
  const buttonToggle = initButtonToggle(isInProgress, setInProgress);
  const { disableButton } = useCallback(buttonToggle, []);

  let menuProps = {};

  if (classes) {
    menuProps = { ...menuProps, classes };
  }

  if (dataCy) {
    menuProps = { ...menuProps, 'data-cy': dataCy };
  }
  return (
    <MenuItem
      disabled={disabledCondition || isInProgress}
      onClick={async () => {
        if (disableButton()) {
          handleSubmit();
        }
      }}
      {...menuProps}
    >
      {children}
    </MenuItem>
  );
}

/**
 * Secondary Button Component without submit mechanism
 */
export const SecondaryActionButton = withStyles({
  root: {
    ...stylesDefaultSecondaryButton,
    fontSize: '14px',
    marginLeft: '10px',
  },
})(Button);

export const AddButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '14px',
    backgroundColor: '#2a7de1',
    borderColor: '#2a7de1',
    borderRadius: '3px',
    color: 'white',
    width: 'auto',
    paddingLeft: '16px',
    paddingRight: '16px',
    height: '36px',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: '#2a7de1',
      borderColor: '#2a7de1',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#2a7de1',
      borderColor: '#2a7de1',
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
})(SubmitButton);

export const CPAButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 700,
    backgroundColor: '#2a7de1',
    borderColor: '#2a7de1',
    borderRadius: '3px',
    color: 'white',
    paddingLeft: '24px',
    paddingRight: '24px',
    width: 'auto',
    height: '36px',
    '&:hover': {
      backgroundColor: '#2a7de1',
      borderColor: '#2a7de1',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#2a7de1',
      borderColor: '#2a7de1',
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
})(SubmitButton);

export const ApprovalButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 700,
    backgroundColor: '#2a7de1',
    borderColor: '#2a7de1',
    borderRadius: '3px',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: 'auto',
    color: 'white',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: '#2a7de1',
      borderColor: '#2a7de1',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#2a7de1',
      borderColor: '#2a7de1',
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
})(SubmitButton);

export const SecondaryButton = withStyles({
  root: {
    ...stylesDefaultSecondaryButton,
    fontSize: '14px',
    marginLeft: '10px',
  },
})(SubmitButton);

export const SquareSubmitButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 13,
    fontWeight: 600,
    backgroundColor: '#2a7de1',
    borderColor: '#2a7de1',
    borderRadius: '3px',
    color: 'white',
    width: 'auto',
    height: '26px',
    marginLeft: '10px',
    paddingLeft: '24px',
    paddingRight: '24px',
    '&:hover': {
      backgroundColor: '#2a7de1',
      borderColor: '#2a7de1',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#2a7de1',
      borderColor: '#2a7de1',
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
})(SubmitButton);

export const DataValidationButton = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 600,
    backgroundColor: '#2a7de1',
    borderColor: '#2a7de1',
    borderRadius: '3px',
    height: '30px',
    width: 'auto',
    color: 'white',
    paddingLeft: '24px',
    paddingRight: '24px',
    '&:hover': {
      backgroundColor: '#2a7de1',
      borderColor: '#2a7de1',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#2a7de1',
      borderColor: '#2a7de1',
    },
    '&:focus': {
      boxShadow: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '11px',
      padding: '20px 10px 20px 10px',
    },
  },
}))(SubmitButton);
