import { getYear } from 'date-fns';
import Cookies from 'js-cookie';
import { now } from 'lodash';
import Button from '../../landing/components/common/Button';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth0 } from '../../libraries/react-auth0-spa';
import { BannerIcon } from '../components/Icons';
import ButtonLink from '../components/common/ButtonLink';
import { LayoutMixin } from '../theme';
import theme from '../theme/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledWrapperContent = styled.div`
  ${LayoutMixin};
  display: flex;
  flex-direction: column;
`;
export const StyledAContentBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid ${theme.colors['Paper White']};
  }
`;
export const StyledTerms = styled.span`
  font-family: ${theme.fontFamily.Inter};
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: ${theme.colors.White};
  margin-top: 24px;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    margin-top: 20px;
    padding: unset;
  }
`;
export const WrapperTerms = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    width: min-content;
    gap: 40px;
    justify-content: center;
    display: flex;
  }
`;
export const StyledItem = styled(Link)`
  font-family: ${theme.fontFamily.Inter};
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  color: ${theme.colors.White};
  margin-bottom: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media screen and (min-width: ${theme.breakpoints[768]}) {
    margin-top: 20px;
    margin-bottom: 0;
  }
`;

export const StyledContainer = styled.div`
  padding-top: 24px;
  height: calc(100vh - 141px);
  @media screen and (min-width: ${theme.breakpoints[768]}) {
    height: calc(100vh - 91px);
  }
  ${LayoutMixin};
`;

export default function MobileLandingPage() {
  const { loginWithRedirect } = useAuth0();
  return (
    <Wrapper>
      <StyledContainer>
        <Link to="/" aria-label="go to home">
          <BannerIcon className="" />
        </Link>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '0px 0px',
            fontSize: '24px',
            marginTop: '50%',
          }}
        >
          <ButtonLink
            to=""
            small={undefined}
            onClick={() => loginWithRedirect({ role: 'manager', action: 'login' })}
            style={{ fontSize: '24px' }}
            disabled={undefined}
            big={undefined}
            underline={undefined}
          >
            <Button>Login {'->'}</Button>
          </ButtonLink>
        </div>
      </StyledContainer>
    </Wrapper>
  );
}

export function LaunchMobileApp() {
  const history = useHistory();
  const { action } = useParams();

  useEffect(() => {
    if (action === 'disable') {
      Cookies.remove('bannerMobileApp');
    } else {
      Cookies.set('bannerMobileApp', 'true', { expires: 7 });
    }

    history.push('/');
  }, [history, action]);

  return (
    <div>
      <h1>Setting Up Mobile App Cookie...</h1>
      <p>You will be redirected shortly.</p>
    </div>
  );
}
