import { rgba } from 'polished';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import styled, { css } from 'styled-components';
import Announcement from '../../../common/Announcement';
import Navbar from '../../../common/Navbar';
import useNavbar from '../../../../hooks/useNavbar';
import theme from '../../../../theme/theme';

const StyledAnnouncement = styled(Announcement)`
  visibility: hidden;
`;
const StyledNavbarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 80;
  display: flex;
  flex-direction: column;

  ${({ isNavbarOpen }) =>
    isNavbarOpen &&
    css`
      height: 100vh;
    `}
`;
const StyledNavbar = styled(Navbar)`
  background: ${({ isScrolling }) => (isScrolling ? rgba(theme.colors.White, 0.9) : 'unset')};

  .navbar-cta-button {
    transition:
      opacity 0.3s,
      transform 0.3s;
    transform: translateY(${({ isScrolling }) => (isScrolling ? 0 : '-100%')});
    opacity: ${({ isScrolling }) => (isScrolling ? 1 : 0)};
  }
`;
function HomeHeader() {
  const [isScrolling, setIsScrolling] = useState(false);
  const { isNavbarOpen, setIsNavbarOpen } = useNavbar();
  const mobileApp = Cookies.get('bannerMobileApp');

  useEffect(() => {
    const changeBackground = () => {
      const windowScrollY = window.scrollY;
      const bodyScrollY = Number(document.body.style.top.replace('px', ''));
      setIsScrolling(windowScrollY >= 66 || bodyScrollY <= -66);
    };
    changeBackground();
    window.addEventListener('scroll', changeBackground);
    return () => window.removeEventListener('scroll', changeBackground);
  }, []);
  return (
    <>
      {!mobileApp && <StyledAnnouncement />}
      <StyledNavbarWrapper isNavbarOpen={isNavbarOpen}>
        {!mobileApp && <Announcement />}
        <StyledNavbar
          isScrolling={isScrolling}
          isNavbarOpen={isNavbarOpen}
          setIsNavbarOpen={setIsNavbarOpen}
        />
      </StyledNavbarWrapper>
    </>
  );
}
export default HomeHeader;
